<template>
  <transition tag="div" class="img-slider" name="slide">
    <tr v-if="transaction">
      <td>
        <router-link
          :to="{
            name: 'Transaction Details',
            params: { id: transaction.id },
          }"
        >
          {{ transaction.id }}</router-link
        >
      </td>
      <td>
        {{ moment(transaction.createdAt).format("DD/MM/yyyy hh:MM:ss a") }}
      </td>

      <td>{{ transaction.service.name }}</td>
      <td>{{ transaction.recipient }}</td>
      <td>{{ transaction.total.toFixed(2) }}</td>
      <td v-if="transaction.wallet">{{ transaction.wallet.fullName }}</td>
      <td v-else>null</td>
      <td>{{ transaction.channel }}</td>
      <td v-if="transaction.paymentMethod">
        {{ transaction.paymentMethod.name }}
      </td>
      <td v-else>null</td>
      <!-- <td>{{ transaction.type }}</td> -->
      <td><status :status="transaction.status" /></td>
      <!-- <td v-if="transaction.status === 'Failed'">
      <div class="d-flex">
        <button
          class="btn retry"
          @click="retry"
          :disabled="transaction.status === 'Complete'"
        >
          Retry
        </button>
        <button
          class="btn mx-2 reverse"
          :disabled="transaction.status === 'Complete'"
        >
          Reverse
        </button>
      </div>
    </td> -->
    </tr>
  </transition>
</template>
<script>
import moment from "moment";
import SwiftAPI from "../services/SwiftAPI";
import Status from "../components/TransactionStatus.vue";
export default {
  components: { Status },
  props: {
    data: Object,
  },
  data() {
    return {
      nos: this.index,
      transaction: this.data,
      loading: false,
    };
  },
  methods: {
    async retry() {
      this.loading = true;

      var response = await SwiftAPI.retryTransaction(
        this.transaction.walletId,
        this.transaction.id
      );
      this.loading = false;

      console.log(response.data);
      alert(response.data);
    },
  },
  computed: {},

  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="css" scoped>
.retry {
  background: #0a95ff;
  font-weight: bold;
  color: white;
}
.reverse {
  background: black;
  color: white;
  font-weight: bold;
}
.transaction td {
  cursor: pointer;
}

table {
  border-collapse: collapse !important;
}
thead td {
  font-weight: bold;
}
</style>
