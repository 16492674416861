<template>
  <div class="customers">
    <table v-if="!loading && $route.name === 'Customers'" class="table">
      <thead>
        <tr>
          <td>#</td>
          <td>Name</td>
          <td>Type</td>
          <td>Last Accessed</td>
          <td>Channel</td>
          <td>Debit</td>
          <td>Credit</td>
          <td>Running Balance</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(customer, index) in customers" :key="customer.id">
          <td>{{ (index += 1) }}</td>
          <td>
            <router-link
              :to="{
                name: 'Customer History',
                params: { id: customer.walletId },
              }"
              >{{ customer.fullName }}</router-link
            >
          </td>
          <td>{{ customer.walletType }}</td>

          <td>
            {{
              moment(customer.modifiedAt).format("ddd MMM DD, YYYY [at] HH:MM")
            }}
          </td>
          <td>
            <button
              class="btn"
              v-for="bot in customer.linkedBots"
              :key="bot.id"
            >
              {{ bot.platform }}
            </button>
          </td>
          <td>{{ customer.debit }}</td>
          <td>{{ customer.credit }}</td>
          <td>{{ customer.runningBalance }}</td>
        </tr>
      </tbody>
    </table>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import SwiftAPI from "../services/SwiftAPI";
export default {
  data() {
    return {
      customers: null,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    var result = await SwiftAPI.getCustomers(1, 100);
    this.customers = result.data;
    console.log(result.data);
    this.loading = false;
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang=""></style>
