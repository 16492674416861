<template>
  <section class="vh-100 gradient-custom">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card" style="border-radius: 1rem">
            <div class="card-body p-5 text-center">
              <div class="mb-md-5 mt-md-4 pb-5">
                <h2 class="fw-bold mb-2 text-uppercase">Login</h2>
                <p class="text-white-50 mb-5">
                  Please enter your login and password!
                </p>

                <div class="form-outline form-white mb-4">
                  <input
                    type="phone"
                    v-model="user.phoneNumber"
                    placeholder="Username"
                    class="form-control form-control-lg"
                  />
                </div>

                <div class="form-outline form-white mb-4">
                  <input
                    type="password"
                    placeholder="Username"
                    id="typePasswordX"
                    v-model="user.password"
                    class="form-control form-control-lg"
                  />
                </div>

                <div v-if="require2fa" class="form-outline form-white mb-4">
                  <input
                    type="number"
                    v-model="user.otp"
                    class="form-control form-control-lg"
                  />
                </div>

                <p class="small mb-5 pb-lg-2">
                  <a class="text-white-50" href="#!">Forgot password?</a>
                </p>

                <button
                  class="btn primary btn-lg text-white px-5"
                  type="submit"
                  :disabled="loading.login"
                  @click="login"
                >
                  <span
                    v-show="loading.login"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="mx-2 sr-only">Login...</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      user: {
        phoneNumber: null,
        password: null,
        otp: null,
      },
      loading: {
        login: false,
      },
      require2fa: false,
    };
  },
  methods: {
    login() {
      this.loading.login = true;
      this.$Swift
        .login(this.user.phoneNumber, this.user.password, this.user.otp)
        .then(
          (response) => {
            this.$store.dispatch("LOGIN_SUCCESS", response.data.data);
            this.$router.push({ name: "Dashboard" });
            this.loading.login = false;
          },
          (error) => {
            this.error = error.response.data.message;
            this.require2fa = true;
            this.loading.login = false;
          }
        );
    },
  },
  created: function () {},
};
</script>
<style lang="css" scoped>
.login {
  background: #d0f7db !important;
}
.copyright {
  font-size: 12px;
  color: gray;
  text-align: center;
}
.primary {
  background: #14ca44;
}
.logo {
  min-width: 100px;
  min-height: 100px;
  width: 50px;
  position: absolute;
  top: 0;
  height: 50px;
  background: url("/logo.png") no-repeat;
  margin-left: 20px;
  margin-top: 20px;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(187, 187, 187);
  padding: 1rem;
}
</style>
