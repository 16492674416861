<template>
  <button
    v-if="status"
    class="btn status"
    :class="status === 'Complete' ? 'success' : 'failed'"
  >
    {{ status }}
  </button>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="css" scoped>
.status {
  width: 100px;
  border-radius: 12px;
}
.success {
  background: #e9f9eb;
  color: #0bbe28;
}
.failed {
  background: #ffeced;
  color: #ec1c24;
}
</style>
