<template>
  <div
    class="d-flex flex-column flex-shrink-0 sidebar"
    style="width: 4.5rem; height: 88%; margin-bottom: 20px"
  >
    <ul class="my-2 nav nav-pills nav-flush flex-column mb-auto text-center">
      <router-link
        class="nav-link"
        v-for="(route, index) in routes"
        :key="index"
        :to="{ name: route.name }"
      >
        <img :src="'/' + route.name + '.png'" alt="" />
      </router-link>
    </ul>
    <ul class="my-2 nav nav-pills nav-flush flex-column mb-auto text-center">
      <button class="btn nav-link" @click.stop="logout">
        <img :src="'/dashboard.png'" alt="" />
      </button>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      routes: [
        {
          path: "dashboard",
          name: "Dashboard",
        },
        {
          path: "transactions",
          name: "Transactions",
        },
        {
          path: "customer",
          name: "Customers",
        },
        {
          path: "partners",
          name: "Partners",
        },
        {
          path: "support",
          name: "Support",
        },
        {
          path: "partners",
          name: "Partners",
        },
        {
          path: "stocks",
          name: "Stocks",
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style lang="css" scoped>
.nav-link {
  margin-top: 20px;
  max-height: 70px;
  padding: 0 !important;
  width: 72px;
}

.nav-link img {
  width: 40px;
  height: 40px;
  background-size: contain;
}
.nav-link :hover,
.nav-link .router-link-active,
.nav-link .router-link-exact-active {
  background-color: indianred;
  cursor: pointer;
}

.sidebar {
  background: #373737;
  border-radius: 25px;
  margin-left: 3px;
  padding: 0 !important;
  position: fixed;
  left: 0;
}
</style>
