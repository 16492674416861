import { createApp } from "vue";
import { VueSignalR } from "@quangdao/vue-signalr";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap";
import SwiftAPI from "@/services/SwiftAPI";
import "bootstrap/scss/bootstrap.scss";

const app = createApp(App);

app.config.globalProperties.$Swift = SwiftAPI;

app.use(store);
app.use(router);
app.use(VueSignalR, {
  url:
    process.env.NODE_ENV === "production"
      ? "https://api.swiftpay.mv/support?access_token=" +
        store.state.user?.token
      : "http://localhost/support?access_token=" + store.state.user?.token,
});

app.mount("#app");
