import http from "axios";
import store from "@/store";
http.interceptors.request.use(
  function (config) {
    config.baseURL =
      process.env.NODE_ENV === "production"
        ? "https://api.swiftpay.mv"
        : "http://localhost";
    const token = store.getters.getUser?.token;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

class SwiftAPI {
  InitDashboard() {
    var date = new Date();
    var from = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      1
    ).toISOString();

    var to = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
    return http.get("/admin/init?from=" + from + "&to=" + to);
  }
  login(phoneNumber, password, otp) {
    return http.post("/auth/login", {
      phoneNumber: phoneNumber,
      password: password,
      code: otp,
    });
  }
  getCustomers(num, size) {
    return http.get("/admin/customers?pageNumber=" + num + "&pageSize=" + size);
  }
  getWallet(walletId) {
    return http.get("/admin/wallet/" + walletId);
  }
  getBotUsers() {
    return http.get("/admin/bot/users");
  }
  sendMessage(id, message) {
    return http.put("/admin/bot/user/" + id + "/" + message);
  }
  getTransactions() {
    return http.get("/admin/transactions?pageNumber=1&pageSize=200");
  }
  getTransaction(reference) {
    return http.get("/admin/transaction/" + reference);
  }
  getTodaySales() {
    return http.get("/admin/sales");
  }
  getTransactionsOfCustomer(id) {
    return http.get(
      "/admin/customers/" + id + "/transactions?pageNumber=1&pageSize=100"
    );
  }
  retryTransaction(walletId, transactionId) {
    return http.put("/admin/customer/" + walletId + "/retry/" + transactionId);
  }
  refund(transactionId) {
    return http.post("/admin/refund/" + transactionId);
  }
}
export default new SwiftAPI();
