<template>
  <div>
    <div v-if="$route.name === 'Transactions'" class="transactions">
      <table v-if="!loading" class="table">
        <thead>
          <tr>
            <td>#</td>
            <td>Date & Time</td>
            <td>Service</td>
            <td>Recipient</td>
            <td>Amount</td>
            <td>Wallet</td>
            <td>Platform</td>
            <td>Channel</td>
            <!-- <td>Type</td> -->
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          <transaction-component
            v-for="transaction in $store.state.dashboard.transactions"
            :key="transaction.id"
            :data="transaction"
          ></transaction-component>
        </tbody>
      </table>
    </div>
    <div v-if="loading" class="spinner-border"></div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import TransactionComponent from "../components/TransactionComponent.vue";
import moment from "moment";
export default {
  components: {
    TransactionComponent,
  },
  data() {
    return {
      transactions: null,
      loading: false,
    };
  },
  async mounted() {
    // this.loading = true;
    // var transactions = await this.$Swift.getTransactions(this.$route.params.id);
    // console.log(transactions);
    // this.transactions = transactions.data;
    // this.loading = false;
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="css" scoped>
.transactions {
  min-height: 87vh;
  padding: 0 10px;
}
.table {
  border-collapse: separate;
}
thead td {
  font-weight: bold;
}
</style>
