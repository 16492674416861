<template>
  <div v-if="thread" class="thread" @click="selectThread(thread)">
    <div style="min-width: 50px">
      <!-- <div
            v-if="user.avatar"
            class="avatar rounded-circle"
            :style="
              'background-image: url(' + user.avatar ??
              '@/components/sp_symbol.png' + ')'
            "
            style="width: 50px; height: 50px"
          ></div> -->
      <div
        class="avatar rounded-circle"
        style="background: url('/sp_symbol.png'); width: 50px; height: 50px"
      ></div>
    </div>
    <div class="mx-2">
      <div class="username">
        {{ thread.username ?? thread.legalName }} -
        <span>{{ thread.platform }}</span>
      </div>
      <!-- <span> | {{ thread.platform }}</span -->
      <div class="last-message">
        <span> {{ getLastMessage(thread) }}</span>
      </div>
    </div>
    <div class="badge">
      {{ thread.count }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    thread: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      convo: this.thread,
      dindex: this.index,
    };
  },
  watch: {
    // "thread.messages": function (a) {
    //   if (a) {
    //     console.log(this.dindex);
    //     var newThread = { ...this.convo };
    //
    //     console.log("Conversation changed");
    //   }
    // },
  },
  methods: {
    selectThread(t) {
      this.$emit("on-select", t);
    },
    getLastMessage(user) {
      var msg = user.messages[user.messages.length - 1];
      if (msg) {
        if (msg.isSent) {
          return "You: " + msg?.messages.substring(0, 30);
        } else {
          return msg?.messages.substring(0, 30);
        }
      } else {
        return "";
      }
    },
  },
  created: function () {
    // eslint-disable-next-line no-unused-vars
    // const unsubscribe = this.$store.subscribeAction((action, state) => {
    //   console.log(action.type);
    //   console.log(action.payload);
    // });
    // you may call unsubscribe to stop the subscription
    //unsubscribe()
  },
};
</script>
<style lang="scss" scoped>
.thread {
  position: relative;
}
.avatar {
  background-size: contain;
  background-position: center;
}
.username {
  color: #707070;
}
.badge {
  position: absolute;
  right: 10px;
  top: 30px;
  height: 20px;
  float: right;
  background: #14ca44;
  color: black;
}
.last-message {
  color: #191847;
  font-size: 12px;
  height: 10px;
  flex-wrap: nowrap;
}
</style>
