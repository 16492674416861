import { createRouter, createWebHistory } from "vue-router";
// eslint-disable-next-line no-unused-vars
import store from "../store/index";
import AppLayout from "@/layouts/AppLayout";
import AuthLayout from "@/layouts/AuthLayout";

import Support from "../views/Support.vue";
import Dashboard from "../views/Dashboard.vue";

import Customers from "../views/Customers.vue";
import CustomerHistory from "../views/Customers/CustomerHistory.vue";

import Partners from "../views/Partners.vue";
import Reports from "../views/Reports.vue";
import Stocks from "../views/Stocks.vue";

import Transactions from "../views/Transactions.vue";
import TransactionDetails from "../views/Transaction/Details.vue";

import Login from "../views/Auth/Login.vue";

const routes = [
  {
    path: "/",
    name: "App",
    component: AppLayout,
    meta: { authorize: ["SuperAdmin", "Admin"] },
    redirect: "dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        meta: { authorize: ["SuperAdmin", "Admin"] },
        component: Dashboard,
      },
      {
        path: "customer",
        name: "Customers",
        component: Customers,
        children: [
          {
            path: ":id",
            name: "Customer History",
            component: CustomerHistory,
          },
        ],
      },
      {
        path: "partners",
        name: "Reports",
        component: Reports,
      },
      {
        path: "partners",
        name: "Partners",
        component: Partners,
      },
      {
        path: "stocks",
        name: "Stocks",
        component: Stocks,
      },
      {
        path: "transactions",
        name: "Transactions",
        component: Transactions,
        children: [
          {
            path: ":id",
            name: "Transaction Details",
            component: TransactionDetails,
          },
        ],
      },
      {
        path: "support",
        name: "Support",
        component: Support,
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthLayout,
    redirect: "login",
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = store.getters.getUser;
  if (authorize) {
    if (!currentUser) {
      return next({ path: "/auth/login" });
    }

    var authorized = false;
    authorize.forEach((permission) => {
      authorized = currentUser?.roles.indexOf(permission) === -1;
    });
    if (!authorized) {
      return next({ path: "/auth/login", query: { returnUrl: to.path } });
    }
  }
  next();
});
export default router;
