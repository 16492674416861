<template>
  <div class="card balance-card">
    <h6>{{ merchant }}</h6>
    <h5>MVR {{ balance.toFixed(2) }}</h5>
  </div>
</template>
<script>
export default {
  props: {
    merchant: {
      type: String,
      default: "Merchant name",
    },
    balance: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="css" scoped>
.balance-card {
  padding: 20px;
  border-radius: 14px;
  width: 100%;
  margin: 10px;
}
</style>
