<template>
  <div v-if="transaction" class="details">
    <table class="table">
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, key) in transaction" :key="key">
          <td style="width: 250px">{{ key }}</td>
          <td v-if="typeof item === 'object'">
            <span class="" v-for="(subItem, subkey) in item" :key="subkey">
              {{ subkey }} : {{ subItem }}
            </span>
          </td>
          <td v-if="Array.isArray(item)">
            <div v-for="arrayItem in item" :key="arrayItem.id">
              <div v-for="(subItem, subkey) in arrayItem" :key="subkey">
                {{ subkey }} : {{ subItem }}
              </div>
            </div>
          </td>
          <td v-if="typeof item === 'string' || typeof item === 'number'">
            <span> {{ item }} </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import SwiftAPI from "../../services/SwiftAPI";
export default {
  data() {
    return {
      nos: this.index,
      transaction: null,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    var transaction = await SwiftAPI.getTransaction(this.$route.params.id);
    this.transaction = transaction.data;
    this.loading = false;
  },
};
</script>
<style lang="css" scoped>
.details {
  min-height: 87vh;
  padding: 10px;
}
</style>
