<template>
  <div v-if="$store.state.support" class="row support">
    <div class="col-md-3 p-0 m-0 mx-1 mt-2 border-end threads">
      <transition-group name="list" tag="div">
        <div v-for="(user, index) in threads" :key="index">
          <thread
            :thread="user"
            :key="user"
            :index="index"
            class="d-flex user p-2 border-bottom list-item"
            @on-select="selectThread(user)"
            :class="selected?.id === user.id ? 'selected' : ''"
          ></thread>
        </div>
      </transition-group>
    </div>
    <div class="col-md chat-area">
      <div class="chat">
        <div ref="chatWindow" id="chatWindow" class="chat-history">
          <ul>
            <li v-for="(chat, index) in messages" :key="index" class="clearfix">
              <div
                :class="chat.isSent ? 'align-right' : 'align-left'"
                class="message-data"
              ></div>
              <div
                :class="
                  chat.isSent
                    ? 'my-message float-right'
                    : 'other-message float-left'
                "
                class="message"
              >
                <span
                  class="text"
                  v-if="chat.type === 'Text' || chat.type === 0"
                  >{{ chat.messages }}</span
                >
                <img
                  v-else
                  @click="openImage(chat.messages)"
                  class="image"
                  :src="chat.messages"
                />
                <div class="date">
                  <span>{{ getTime(chat.createdAt) }}</span>
                  <span class="pt-2">- {{ chat.createdBy }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="chat-message clearfix">
          <textarea
            name="message-to-send"
            id="message-to-send"
            placeholder="Type your message"
            rows="2"
            v-model="message"
          ></textarea>

          <i class="fa fa-file-o"></i> &nbsp;&nbsp;&nbsp;
          <i class="fa fa-file-image-o"></i>

          <div class="d-flex justify-content-between">
            <div class="d-flex quick-replies">
              <button
                class="btn btn-secondary mx-2"
                @click="sendMessage(reply)"
                v-for="reply in quickReplies"
                :key="reply"
              >
                {{ reply }}
              </button>
            </div>
            <button
              class="btn btn-primary"
              style="width: 100px"
              @click="sendMessage(message)"
            >
              Send
            </button>
          </div>
        </div>
        <!-- end chat-message -->
      </div>
    </div>
    <div class="col-md-4 border-start info" v-if="selected">
      <div v-if="!loading.wallet">
        <div>
          <p>{{ selected.wallet.fullName }}</p>
        </div>
        <div class="balances">
          <div class="d-flex justify-content-between">
            <h6>Balance: {{ selected.wallet.runningBalance }}</h6>
          </div>
          <div class="transactions">
            <table class="table table-bordered">
              <tbody>
                <tr
                  class="transaction"
                  v-for="transaction in selected.wallet.transactions.filter(
                    (d) => d.type === 'Debit'
                  )"
                  :key="transaction.id"
                >
                  <td>{{ transaction.service?.name }}</td>
                  <td>{{ transaction.total }}</td>
                  <td>{{ transaction.recipient }}</td>
                  <td>{{ transaction.status }}</td>
                  <td v-if="transaction.status !== 'Complete'">
                    <span
                      v-if="transaction.loading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span
                      v-else
                      @click="refund(transaction)"
                      class="refund mx-2 sr-only"
                      >Refund</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="spinner-border m-5" v-show="loading.wallet" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Thread from "../components/Thread.vue";
export default {
  name: "Home",
  components: { Thread },
  data() {
    return {
      chats: [],
      selected: null,
      message: null,
      scroll: null,
      wallet: null,
      loading: {
        wallet: false,
        users: false,
        messages: false,
        refund: false,
      },
      quickReplies: [
        "Thank you 🙂",
        "You're most welcome 🙂",
        "Appologies for the inconvenience ",
      ],
    };
  },
  watch: {
    selected: function () {
      this.scrollToEnd();
    },
    "$store.state.notification": function (a) {
      if (a) {
        this.playSound();
        this.scrollToEnd();
      }
    },
  },
  methods: {
    openImage(url) {
      window.open(url, "_blank").focus();
    },
    timeSince(dt) {
      var date = new Date(dt);
      var seconds = Math.floor((new Date() - date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }

      return "Just now";
    },
    getTime(date) {
      return this.timeSince(date);
    },
    async selectThread(user) {
      this.selected = user;
      this.selected.count = null;
      this.getWallet();
    },

    async getWallet() {
      if (this.selected.wallet) {
        return;
      }

      this.loading.wallet = true;
      var result = await this.$Swift.getWallet(this.selected.walletId);
      this.loading.wallet = false;
      console.log(result);
      this.selected.wallet = result.data;
    },
    scrollToEnd: function () {
      var content = this.$refs.chatWindow;
      content.scrollTop = content.scrollHeight;
    },
    sendMessage: async function (msg) {
      var msgUser = this.selected;
      this.message = null;

      await this.$Swift.sendMessage(msgUser.id, msg);
    },
    refund(transaction) {
      if (confirm("Are you sure you want to refund this transaction?")) {
        transaction.loading = true;
        this.$Swift.refund(transaction.id).then(
          (response) => {
            var index = this.selected.wallet.transactions.findIndex(
              (d) => d.id === transaction.id
            );

            transaction.loading = false;
            this.selected.wallet.transactions[index] = response.data.data;
          },
          () => {
            transaction.loading = false;
          }
        );
      } else {
        console.log("error");
        // Do nothing!
      }
    },
  },
  computed: {
    messages: function () {
      return this.selected?.messages;
    },
    threads: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.state.support.sort((a, b) => a.count > b.count);
    },
  },
  mounted: async function () {},
  created: async function () {
    this.moment = moment;
    var users = await this.$Swift.getBotUsers();
    this.$store.commit("INIT_SUPPORT", users.data);
  },
};
</script>
<style lang="scss" scoped>
.refund {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.threads {
  background: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  max-height: 86vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.chat-area {
  background: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  max-height: 86vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.support {
  background: #f2f2f2;
  overflow: hidden;
}

.info {
  background: white;
  border-radius: 20px;
  max-height: 86vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.quick-replies {
  max-width: 350px;
  overflow-x: auto;
  overflow-y: hidden;
}
.support {
  height: 88vh;
}
.user {
  cursor: pointer;
  &.selected {
    background: rgb(207, 207, 207);
  }
}

.row {
  margin: 0 !important;
  padding: 0 !important;
}
ul {
  list-style: none !important;
}
$green: #14ca44;
$blue: #39a0c1;
$orange: #e38968;
$gray: #92959e;
$username: #707070;

.chat {
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  .chat-header {
    padding: 20px;
    border-bottom: 2px solid white;

    img {
      float: left;
    }

    .chat-about {
      float: left;
      padding-left: 10px;
      margin-top: 6px;
    }

    .chat-with {
      font-weight: bold;
      font-size: 16px;
    }

    .chat-num-messages {
      color: $gray;
    }

    .fa-star {
      float: right;
      color: #d8dadf;
      font-size: 20px;
      margin-top: 12px;
    }
  }

  .chat-history {
    padding: 10px 10px 5px 5px;
    border-bottom: 2px solid white;
    height: 58vh;
    overflow-y: auto;

    .message-data {
      margin-bottom: 2px;
    }

    .message-data-time {
      color: lighten($gray, 8%);
      padding-left: 6px;
    }

    .message {
      color: white;
      font-size: 12px;
      padding: 15px;
      border-radius: 20px;
      margin-bottom: 10px;
      max-width: 70%;
      width: auto;
      position: relative;

      .image {
        padding: 0;
        border-radius: 20px;
        max-width: 100% !important;
        width: auto;
        cursor: pointer;
      }

      .date {
        position: absolute;
        right: 15px;
        bottom: 3px;
        font-size: 8px;
      }
    }

    .my-message {
      background: $green;
    }

    .other-message {
      background: $blue;
    }
  }

  .chat-message {
    padding: 30px;
    position: fixed;

    bottom: 0;
    textarea {
      width: 100%;
      padding: 10px 20px;
      border-radius: 5px;
      resize: none;
    }

    .fa-file-o,
    .fa-file-image-o {
      font-size: 16px;
      color: gray;
      cursor: pointer;
    }

    .btn {
      height: 25px;
      padding: 0;
    }
  }
}

.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: $green;
}

.offline {
  color: $orange;
}

.me {
  color: $blue;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
</style>
