<template>
  <div v-if="$store.state.dashboard" class="dashboard">
    <!-- <div class="chart"></div> -->
    <div v-if="$store.state.dashboard" class="merchant-balances d-flex">
      <balance-card
        v-for="wallet in $store.state.dashboard.wallet"
        :key="wallet.id"
        :merchant="wallet.fullName"
        :balance="wallet.runningBalance"
      />
    </div>
    <!-- <div class="chart" v-if="$store.state.dashboard.chartData">
      <bar-chart />
    </div> -->
    <div class="transctions"></div>
  </div>
</template>
<script>
import BalanceCard from "../components/BalanceCard.vue";
import moment from "moment";
import SwiftAPI from "../services/SwiftAPI";
// import BarChart from "@/components/Charts/BarChart.vue";
export default {
  components: {
    BalanceCard,
    // BarChart,
  },
  data() {
    return {
      transactions: null,
      loading: false,
    };
  },
  methods: {
    add() {
      this.doughnutChart.data.datasets[0].data = [100, 500];
    },
    retry(walletId, transactionId) {
      SwiftAPI.retryTransaction(walletId, transactionId).then((response) => {
        alert(response.data.message);
      });
    },
  },

  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="css" scoped>
#salesChart {
  height: 65vh !important;
}
</style>
