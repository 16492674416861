<template>
  <div class="customer-transactions">
    <table class="table">
      <thead>
        <tr>
          <td>#</td>
          <td>Date & Time</td>
          <td>Reference Id</td>
          <td>Service</td>
          <td>Recipient</td>
          <td>Amount</td>
          <td>Platform</td>
          <td>Channel</td>
          <td>Type</td>
          <td>Status</td>
          <td>Action</td>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <TransactionComponent
          v-for="(transaction, index) in transactions"
          :key="transaction.id"
          :index="index"
          :data="transaction"
        ></TransactionComponent>
      </tbody>
    </table>
  </div>
</template>
<script>
import TransactionComponent from "../../components/TransactionComponent.vue";
import moment from "moment";
import SwiftAPI from "../../services/SwiftAPI";
export default {
  components: {
    TransactionComponent,
  },
  data() {
    return {
      transactions: null,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    var transactions = await SwiftAPI.getTransactionsOfCustomer(
      this.$route.params.id
    );
    console.log(transactions);
    this.transactions = transactions.data;
    this.loading = false;
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="css" scoped>
.customer-transactions {
  min-height: 87vh;
  padding: 0 10px;
}
.table {
  border-collapse: separate;
}
thead td {
  font-weight: bold;
}
</style>
