import { createStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    dashboard: null,
    user: null,
    support: null,
    notification: false,
    users: [],
    coversations: [],
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    INIT_DASHBOARD(state, payload) {
      state.dashboard = payload;
    },
    INIT_SUPPORT(state, payload) {
      state.support = payload;
    },
    LOGIN_SUCCESS(state, payload) {
      state.user = payload;
    },
    LOGOUT(state) {
      state.user = null;
    },
    MESSAGE_RECEIVED(state, message) {
      var msgUser = state.support.find((x) => x.id === message.by.id);
      var index = state.support.findIndex((x) => x.id === message.by?.id);
      console.log(index);
      state.support.splice(index, 1);
      state.support.unshift(msgUser);
      if (msgUser.count) {
        msgUser.count++;
      } else {
        msgUser.count = 1;
      }
      msgUser.lastMessage = message.modifiedAt;
      msgUser.messages.push(message);
    },
  },
  actions: {
    MESSAGE_RECEIVED({ commit }, message) {
      commit("MESSAGE_RECEIVED", message);
    },

    LOGIN_SUCCESS({ commit }, payload) {
      commit("LOGIN_SUCCESS", payload);
    },
    LOGOUT({ commit }, payload) {
      commit("LOGOUT", payload);
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: "SwiftPay Store",
      paths: ["user"],
    }),
  ],
});
